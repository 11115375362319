import { Box, List, ListItem, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import type { PlanType, Plan } from '.';
import { PlanButton } from './PlanButton';
import { PriceItem } from './PriceItem';
import { plansColors } from './colors/plansColors';
import { StyledTooltip } from './StyledTooltip';
import { PlanFeatures } from './PlanFeatures';
import { usePlan } from '~/services/stripe.services';
import { useRouter } from 'next/router';
import { CheckCircle } from '@material-ui/icons';
import { CardWrapper, HeaderCard, PlanTitle } from './components/styled.components';
import clsx from 'clsx';
import NewPriceItem from './NewPriceItem';

export type PlanCardStatus = 'skeleton' | 'loading' | 'disabled' | 'default';

interface NewPrices {
  type: string;
  interval: string;
  price: number;
  nextPrice: number;
  percentage: number;
}

interface Props {
  status: PlanCardStatus;
  isRecommended: boolean;
  isSelected?: boolean;
  specialOffer?: string;
  plan: PlanType;
  title: string;
  description: string;
  features: Plan['list'];
  category: string;
  price: string;
  offerPrice: string;
  numberOfVideos: number;
  updatingPrices?: NewPrices;
  isBeforeEndDate?: boolean;
  onPlanSelection: () => void;
}

function RecommendedBadge({ text }: { text: string }) {
  return (
    <Box
      position="absolute"
      zIndex="1"
      top="-1px"
      width="320px"
      textAlign="center"
      style={{
        backgroundColor: '#7c11f1',
        padding: '4px 12px 4px 12px',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        color: 'white',
        fontSize: 16
      }}
    >
      <span>{text}</span>
    </Box>
  );
}

const useStyles = makeStyles<any, { isVideo: boolean; isPlansRoute: boolean }>((theme) => ({
  divDescription: ({ isPlansRoute, isVideo }) => ({
    textAlign: 'center',
    lineHeight: '55px',
    display: 'inline',
    width: '100%',
    ...(isVideo
      ? isPlansRoute
        ? {
            height: 65
          }
        : {
            [theme.breakpoints.down(965)]: { height: 65 },
            [theme.breakpoints.down(691)]: { height: 'auto' }
          }
      : isPlansRoute
      ? {
          height: 65
        }
      : {
          [theme.breakpoints.down(1009)]: {
            height: 65
          },
          [theme.breakpoints.down(957)]: {
            height: 'auto'
          },
          [theme.breakpoints.down(757)]: {
            height: 65
          },
          [theme.breakpoints.down(690)]: {
            height: 'auto'
          }
        })
  }),
  planIconBasic: {
    color: '#7c11f1'
  },
  planBasicBorder: {
    border: '2px solid #7c11f1',
    borderRadius: '0 0 16px 16px'
  },
  planExplorerBorder: {
    border: '2px solid #414042'
  },
  planIconPro: {
    color: '#0086ff'
  },
  planProBorder: {
    border: '2px solid #0086ff'
  },
  planIconBusiness: {
    color: '#5bc005'
  },
  planBusinessBorder: {
    border: '2px solid #5bc005'
  },
  planFreeBorder: {
    border: '2px solid #e1e1e1'
  }
}));

export function PlanCard({
  isRecommended,
  status,
  plan,
  title,
  description,
  features,
  category,
  isSelected,
  specialOffer,
  price,
  offerPrice,
  numberOfVideos,
  isBeforeEndDate,
  updatingPrices,
  onPlanSelection
}: Props) {
  const { plan: activePlan } = usePlan();
  const { asPath } = useRouter();
  const free = plan === 'Free';
  const basic = plan === 'Basic';
  const explorer = plan === 'Explorer';
  const pro = plan === 'Pro';
  const business = plan === 'Business';

  const classes = useStyles({ isVideo: category === 'video', isPlansRoute: asPath === '/plans' });
  if (status === 'skeleton') {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        width={268}
        height={460}
        style={{ borderRadius: 8 }}
      />
    );
  }

  const {
    primary: planColor,
    accent: planColorAccent,
    hover: planColorHover,
    font: planColorFont,
    link: planColorLink = 'inherit',
    title: planTitleColor,
    btnBG: planButtonColor
  } = plansColors[plan];
  const cssVars = {
    '--color-from': planColorAccent,
    '--color-to': planColor,
    '--color-plan': planColor,
    '--color-bg': planColor,
    '--color-hover': planColorHover,
    '--color-font': planColorFont,
    '--color-link': planColorLink
  } as React.CSSProperties;
  return (
    <Box style={cssVars} height="100%" position="relative" paddingTop="16px">
      {basic ? <RecommendedBadge text="Most popular" /> : null}

      <div
        style={{
          minHeight: category === 'widget' ? 440 : 'auto',
          width: '100%',
          height: '100%'
        }}
      >
        <CardWrapper
          className={clsx(
            free && classes.planFreeBorder,
            basic && classes.planBasicBorder,
            explorer && classes.planExplorerBorder,
            pro && classes.planProBorder,
            business && classes.planBusinessBorder
          )}
        >
          <div>
            <HeaderCard>
              {title && (
                <PlanTitle
                  style={{
                    color: planTitleColor
                  }}
                >
                  {title.split(' ')[0]}
                </PlanTitle>
              )}
              {isBeforeEndDate && updatingPrices !== undefined && category === 'video' ? (
                <NewPriceItem
                  plan={plan}
                  newPrice={updatingPrices}
                  color={plan === 'Basic' ? '#7C11F1' : '#0086ff'}
                  price={price}
                  offerPrice={offerPrice}
                />
              ) : (
                <PriceItem
                  plan={plan}
                  specialOffer={specialOffer}
                  price={price}
                  offerPrice={offerPrice}
                />
              )}
            </HeaderCard>

            <div style={{ minHeight: category === 'video' ? 56 : 0 }}>
              {category === 'video' &&
                (plan === 'Free' ? (
                  <p>For anyone who wishes to try Woxo.</p>
                ) : plan === 'Basic' ? (
                  <p>For content creators and entrepreneurs that put out videos every day.</p>
                ) : plan === 'Explorer' ? (
                  <p>
                    For content creators, entrepreneurs are just starting in the content journey.
                  </p>
                ) : (
                  <p>
                    For agencies and large teams that need to create videos at scale and automate
                    content.
                  </p>
                ))}
            </div>

            <div style={{ minHeight: category === 'widget' ? 56 : 0 }}>
              {category === 'widget' ? (
                plan === 'Free' ? (
                  <p>For individuals working on quick projects</p>
                ) : plan === 'Basic' ? (
                  <p>Ideal for more customization & productivity.</p>
                ) : plan === 'Pro' ? (
                  <p>Ideal for small businesses or freelancers.</p>
                ) : (
                  <p>Ideal for teams with collaboration needs.</p>
                )
              ) : null}
            </div>
            <div style={{ margin: '1rem 0' }}>
              <PlanButton
                onClick={onPlanSelection}
                style={{
                  backgroundColor:
                    isSelected || (plan === 'Free' && !activePlan) ? '#e1e1e1' : planButtonColor
                }}
                disabled={
                  status === 'disabled' ||
                  status === 'loading' ||
                  isSelected ||
                  (plan === 'Free' && !activePlan)
                }
              >
                {status === 'loading' ? (
                  'Loading...'
                ) : (
                  <span data-name={`btn-plan-type-premium-${plan}`}>
                    {isSelected || (plan === 'Free' && !activePlan)
                      ? 'Your current plan'
                      : plan === 'Free'
                      ? 'Try it for free'
                      : 'Get Started'}
                  </span>
                )}
              </PlanButton>
            </div>
            {category === 'video' && <PlanFeatures plan={plan as Exclude<PlanType, 'Business'>} />}
            {category === 'widget' && (
              <List style={{ width: '100%' }} dense={true} disablePadding={true}>
                {features.map((item) => {
                  return (
                    <Box key={item.id} display="flex" alignItems="center" style={{ minHeight: 48 }}>
                      <ListItemIcon
                        style={{
                          minWidth: 35
                        }}
                        className={clsx(
                          basic && classes.planIconBasic,
                          pro && classes.planIconPro,
                          business && classes.planIconBusiness
                        )}
                      >
                        <CheckCircle />
                      </ListItemIcon>
                      <ListItem disableGutters dense>
                        {item.value}
                      </ListItem>
                    </Box>
                  );
                })}
              </List>
            )}
          </div>
        </CardWrapper>
      </div>
    </Box>
  );
}
