import NextLink from 'next/link';
import { ChevronRight } from '@material-ui/icons';
import { Box, Typography, Link as MaterialLink, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

import bannerData from '~/services/banner.json';
import { gtmFireEvent } from '~/helpers/gtm';
import getWoxoLocationFromPath from '~/hooks/helpers/signUpHelper';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from 'react';
import { useRouter } from 'next/router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Toast from '../../helpers/notification';

const useStyles = makeStyles((theme) => ({
  banner: {
    width: '100%',
    position: 'relative',
    padding: '12px 40px',
    backgroundColor: '#166df9',
    animation: `$myEffectBanner 1.5s cubic-bezier(0.42, 0, 0.58, 1) 1.8s forwards`,
    transform: 'translateY(-100%)',
    color: 'white',
    textAlign: 'center',

    '& h6': {
      fontSize: 12
    },
    '& a': {
      fontSize: 12
    },
    '& svg': {
      width: 16,
      height: 16
    }
  },
  '@keyframes myEffectBanner': {
    '0%': {
      transform: 'translateY(-100%)',
      backgroundColor: '#166df9',
      color: 'white'
    },
    '50%': {
      transform: 'translateY(0)',
      color: 'white',
      backgroundColor: '#166df9'
    },
    '100%': {
      color: 'initial',
      transform: 'translateY(0)',
      backgroundColor: '#f4f4f4'
    }
  },
  linkBanner: {
    color: 'white',
    display: 'inline-flex',
    alignItems: 'center',
    animation: `$myEffectAnchor 1.5s cubic-bezier(0.42, 0, 0.58, 1) 1.8s forwards`,
    marginLeft: '10px'
  },
  '@keyframes myEffectAnchor': {
    '0%': { color: 'white' },
    '50%': { color: 'white' },
    '100%': {
      color: '#166df9'
    }
  },
  fakeBanner: {
    width: '100%',
    padding: '12px 40px',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    visibility: 'hidden',

    '& h6': {
      fontSize: 12
    },
    '& a': {
      fontSize: 12
    },
    '& svg': {
      width: 16,
      height: 16
    }
  },
  closeIcon: {
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 16,
    display: 'flex',
    alignItems: 'center',
    '& button': {
      animation: `$myEffectCloseIcon 1.5s cubic-bezier(0.42, 0, 0.58, 1) 1.8s forwards`,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'white'
      }
    }
  },
  '@keyframes myEffectCloseIcon': {
    '0%': { color: 'white' },
    '50%': { color: 'white' },
    '100%': {}
  },
  promoCode: {
    cursor: 'pointer',
    'font-weight': 700,
    background: 'linear-gradient(230deg, #0082f9,#8656da)',
    '-webkit-background-clip': 'text',
    color: 'transparent'
  }
}));

export const BannerContext = createContext<{
  isOpen: boolean;
  isActive: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsActive: Dispatch<SetStateAction<boolean>>;
}>({ isOpen: true, isActive: true, setIsOpen: () => {}, setIsActive: () => {} });

export const BannerContextProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <BannerContext.Provider value={{ isOpen, isActive, setIsOpen, setIsActive }}>
      {children}
    </BannerContext.Provider>
  );
};

//This component is used to be a placeholder in the document to
//displace the whole document the same as the Banner height
export const FakeBannerPlaceHolder = () => {
  const classes = useStyles();
  const { asPath } = useRouter();
  const { isOpen } = useContext(BannerContext);

  if (!bannerData.text || !isOpen) {
    return null;
  }

  return (
    <Box className={classes.fakeBanner} tabIndex={-1}>
      <Typography display="inline" variant="subtitle1">
        {asPath === '/plans' && bannerData.text}
      </Typography>
      {asPath !== '/plans' && (
        <Box marginLeft={'10px'} fontSize={12} display="inline">
          Learn More <ChevronRight fontSize="small" />
        </Box>
      )}
    </Box>
  );
};

const Banner = () => {
  const { isOpen, setIsOpen } = useContext(BannerContext);
  const classes = useStyles();
  const { asPath } = useRouter();

  if (!bannerData.text || !isOpen) {
    return null;
  }

  const onLinkClick = () => {
    gtmFireEvent({
      event: 'onButtonClick',
      category: getWoxoLocationFromPath(),
      action: 'learnmore-banner-click',
      label: 'Learm More Banner (Redirects to Pricing)'
    });
  };

  const handleCopyToClipboard = () => {
    Toast.success(
      'Code copied to clipboard. Apply on checkout page.',
      '',
      { duration: 4500, id: 'clipboard', style: { maxWidth: '450px' } },
      false
    );
  };

  const handleClose = () => {
    sessionStorage.setItem('is_banner_open', 'false');
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <Box className={classes.banner}>
          <Box className={classes.closeIcon}>
            <IconButton onClick={handleClose} size="small">
              <Close />
            </IconButton>
          </Box>
          <Typography display="inline" variant="subtitle1">
            <span>
              {bannerData.text}
              <CopyToClipboard text={bannerData.code} onCopy={handleCopyToClipboard}>
                <span className={classes.promoCode}> {bannerData.code}</span>
              </CopyToClipboard>
            </span>
          </Typography>
          {asPath !== '/plans' && (
            <NextLink passHref href="/plans" legacyBehavior>
              <MaterialLink onClick={onLinkClick} className={classes.linkBanner}>
                Learn More <ChevronRight fontSize="small" />
              </MaterialLink>
            </NextLink>
          )}
        </Box>
      )}
    </>
  );
};

export default Banner;
